/*-----------------------------------------------
|   Button
-----------------------------------------------*/
.btn {
  letter-spacing: 0.1em;
}

.btn-link{font-weight: $font-weight-bold;}

.btn.btn-outline-light.border-2x { border-color: rgba($white, 0.5); }

.btn-outline-light:hover, .btn-light, .btn-light:hover { color: $dark; }
.btn-xs{ padding: .2rem .8rem; }

.btn-success, .btn-info{
  color: $white;
}
.btn-light{
  color: $black;
}

// // Gradient Button
.btn-rounded-gradient-primary {
  color: $white;
  font-weight: bold;
  background: linear-gradient(308.36deg, #6A11FA -22.01%, #40CFFC 125.61%);
  width: 11.25rem;
  border:0;
  border-radius: 10rem !important;
  &:hover{
    color: $white;
  }
}

.btn-gradient-primary {
  color: $white;
  font-weight: bold;
  background: linear-gradient(308.36deg, #6A11FA -22.01%, #40CFFC 125.61%);
  border-radius: 9px;
  width: 8.5rem;
  border:0;
  &:hover,
  &:focus {
    color: $white;
    background: linear-gradient(308.36deg, #6A11FA -20.01%, #40CFFC 100.61%);
  }
  @include media-breakpoint-up(sm) {
    width: 11.25rem;
  }
}



.btn-gradient-warning {
  color: $white;
  font-weight: bold;
  background: linear-gradient(133.25deg, #F5C20B 1%, #FE521C 120.29%);
  border-radius: 9px;
  width: 8.5rem;
  border:0;
  &:hover,
  &:focus {
    color: $white;
    background: linear-gradient(120.25deg, #F5C20B 1.66%, #FE521C 142.29%);
  }
  @include media-breakpoint-up(sm) {
      width: 11.25rem;
  }
}



.btn-boots-primary {
  color: $white;
  font-weight: bold;
  background: linear-gradient(308.36deg, #6A11FA -22.01%, #40CFFC 125.61%);
  border-radius: 9px;
  width: 14rem;
  border:0;
  &:hover,
  &:focus {
    color: $white;
    background: linear-gradient(308.36deg, #6A11FA -20.01%, #40CFFC 100.61%);
  }
  @include media-breakpoint-up(xl) {
    width: 25.569rem;
  }
}

.btn-boots-warning {
  color: $white;
  font-weight: bold;
  background: linear-gradient(133.25deg, #F5C20B 1%, #FE521C 120.29%);
  border-radius: 9px;
  width: 14rem;
  border:0;
  &:hover,
  &:focus {
    color: $white;
    background: linear-gradient(120.25deg, #F5C20B 1.66%, #FE521C 142.29%);
  }
  @include media-breakpoint-up(xxl) {
    width: 25.569rem;
  } 
}

.btn-boots-purple {
  color: $white;
  font-weight: bold;
  background: linear-gradient(133.25deg, rgba(116, 30, 255, 1), rgba(255, 55, 187, 1));
  border-radius: 9px;
  width:14rem;
  border:0;
  &:hover,
  &:focus {
    color: $white;
    background: linear-gradient(120.25deg, rgba(116, 30, 255, 1), rgba(255, 55, 187, 1));
  }
  @include media-breakpoint-up(xl) {
    width: 25.569rem;
  }    
} 

.btn-boots-red {
  color: $white;
  font-weight: bold;
  background: linear-gradient(133.25deg, rgba(10, 10, 10, 0.611), rgba(255, 11, 11));
  border-radius: 9px;
  width:14rem;
  border:0;
  &:hover,
  &:focus {
    color: $white;
    background: linear-gradient(120.25deg, rgba(10, 10, 10, 0.611), rgb(255, 93, 17));
  }
  @include media-breakpoint-up(xl) {
    width: 25.569rem;
  }    
} 
       
         
.btn-link{
  color: $light;
}

.btn-close-boots {
  cursor: pointer;
  position: relative;
  width: 25px;
  height: 25px;
  &:after,
  &:before {
    position: absolute;
    left: 75%;
    top: 32%;
    content: ' ';
    height: 1.5rem;
    width: 3px;
    background-color: $white;
    @include media-breakpoint-up(lg) { 
      left: 7px;
      top:5px;
    }
  }
  &:after{
    transform: rotate(-45deg);
  }
  &:before{
    transform: rotate(45deg);
  }
}

.btn-close-boots-container {
  position: fixed;
  height: 2.5rem;
  width: 2.5rem;
  background-color: $black;
  border-radius: 50%;
  right: 3.5rem;
  top: 2rem;
  z-index: 1;
  @include media-breakpoint-up(lg) { 
    position: absolute;
    background-color: transparent;
    height: 0;
    width: 0;
    border-radius: 0;
  }
}