// #preloader {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 10000;
//     background: #fff url('../img/loader-3.gif') no-repeat center center;
//   }  
/*-----------------------------------------------
|   Pre-loader
-----------------------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background-color: $white;
  opacity: 1;
  // transition: opacity 0.8s cubic-bezier(.77, 0, .18, 1);
  // background-image: url('/assets/img/icons/linkdin.png');
  // background-image: url('/assets/img/icons/BaskBall.svg');
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(lg){
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: map_get($spacers, 8);
    padding-bottom: map_get($spacers, 6);
  ;
  }
  &.loaded{
    opacity: 0;
  }
}

.line-scale-pulse-out-rapid{
  div{
    background-color: $black;
    width: 0.125rem;
    border-radius: $border-radius;
  }
}


  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader {
    position: absolute;
    width: 200px;
    height: 200px;
    background-image: url('/assets/img/icons/BaskBall.svg');
  }
  
  @-webkit-keyframes preloader_6_span {
    0%, 100% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(0.5);
    }
  }
  
  
  @keyframes preloader_6_span {
    0%, 100% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(0.5);
    }
  }