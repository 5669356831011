
.bg-pricing-gradient {
    background: linear-gradient(to bottom left, rgba(255, 108, 99, 5%) ,rgba(210, 18, 122, 5%)  );
  }

  .bg-pricing-gradient1 {
    color: $black;
    font-weight: bold;
    background: linear-gradient(133.25deg, rgba(170, 168, 168, 0.81), rgba(240, 240, 240, 0.753));
    border-radius: 9px;
    border:0;
    &:hover,
    &:focus {
      color: $black;
      background: linear-gradient(200.25deg, rgb(236, 236, 236), rgba(205, 64, 64, 0.911));
    }
  }   


.btn-klean-outline { 
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:inherit;
    background-size:auto;
    background: linear-gradient(50deg, $info, $primary);
    mask: url('data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" ><rect x="1" y="1" width="100%" height="100%" style="height:calc(100% - 2px);width:calc(100% - 2px)" rx="6" ry="6" stroke-width="1" fill="transparent" stroke="white"/></svg>') 0 / 100% 100%;
  }
  &:hover{
    background: $light;
    border:aliceblue;
  }
 
}  


.btn-pricing-danger {
    color: $white;
    font-weight: bold;
    background: linear-gradient(133.25deg, rgba(255, 108, 99, 1), rgba(210, 18, 122, 1));
    border-radius: 9px;
    border:0;
    &:hover,
    &:focus {
      color: $white;
      background: linear-gradient(200.25deg, rgba(255, 108, 99, 1), rgba(210, 18, 122, 1));
    }
  }    